<template>
    <div class="tone-detail__box tone-detail__box_reason-table box">
        <table class="tone-detail__table tone-detail__table_reasons tone-detail__table_striped">
            <caption class="tone-detail__table-caption">{{reportToneReason.title}}</caption>
            <colgroup>
                <col class="tone-detail__table-column tone-detail__table-column_reasons-title">
                <col class="tone-detail__table-column tone-detail__table-column_reasons-total">
            </colgroup>
            <thead>
                <tr class="tone-detail__table-row tone-detail__table-row_head">
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_sortable" scope="col">
                        <span class="tone-detail__table-value">Источник</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable" scope="col">
                        <span class="tone-detail__table-value">Количество</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{reportDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneReason.total | intFormat}}</span>
                            <difference
                                v-if="compareToneReason && compareToneReason.total"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneReason.total"
                                :compareValue="compareToneReason.total"
                                mode="percentage"
                                good="negative"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                </tr>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{compareDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneReason">
                            {{compareToneReason.total | intFormat}}
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareReasonsItemBox",
        components: {
            Difference,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                type: Object,
                required: true
            },
            reportToneReason: {
                type: Object,
                required: true
            },
            compareToneReasons: {
                type: Array,
                required: true
            },
        },
        computed: {
            compareToneReason() {
                return this.compareToneReasons?.find(({ title }) => title === this.reportToneReason.title);
            }
        },
        filters: {
            daterangeText
        },
    }
</script>
