<template>
    <fieldset class="daterange-picker">
        <div class="daterange-picker__dates">
            <input class="daterange-picker__date" :name="`${name}-start`" :value="daterange.start | dateText"  type="hidden" />
            <input class="daterange-picker__date" :name="`${name}-end`" :value="daterange.end | dateText"  type="hidden" />
        </div>
        <div class="daterange-picker__info">
            <legend class="daterange-picker__title daterange-picker__title_data h3"><slot>Период</slot></legend>
            <button class="daterange-picker__value" @click="showCalendarModal">{{daterange | daterangeText}}</button>
        </div>
        <modal class="daterange-picker__modal"
            classes="daterange-picker__modal-content"
            @before-close="handleClose"
            :name="name"
            :min-width="300"
            :max-width="510"
            :adaptive="true"
        >
            <p class="daterange-picker__title daterange-picker__title_modal h2"><slot>Период</slot></p>
            <p class="daterange-picker__error" v-if="error">{{error}}</p>
            <button class="daterange-picker__close" @click="closeCalendarModal">
                <svg-icon class="daterange-picker__icon" icon="close" />
            </button>
            <v-date-picker v-model="daterange"
                :columns="calendarLayout.columns"
                :is-expanded="calendarLayout.isExpanded"
                :min-date="minDate"
                :max-date="maxDate"
                color="red"
                is-range
            />
        </modal>
    </fieldset>
</template>

<script>
    import { daterangeText, dateText, minDaterangeDate } from "@/helpers/daterange";
    import endOfYesterday from "date-fns/endOfYesterday";

    let yesterday = endOfYesterday()

    export default {
        name: "DaterangePicker",
        props: {
            name: {
                type: String,
                required: true
            },
            value: {
                type: Object,
                required: true
            },
            minDate: {
                type: Date,
                default() {
                    return minDaterangeDate();
                }
            },
            maxDate: {
                type: Date,
                default() {
                    return yesterday;
                }
            }
        },
        data() {
            return {
                error: ""
            };
        },
        computed: {
            daterange: {
                get() {
                    return this.value;
                },
                set(daterange) {
                    this.error = "";
                    this.$emit("input", daterange);
                    this.closeCalendarModal();
                }
            },
            calendarLayout() {
                return this.$screens({
                    default: {
                        columns: 1,
                        isExpanded: true
                    },
                    desktop: {
                        columns: 2,
                        isExpanded: false
                    }
                });
            }
        },
        methods: {
            showCalendarModal() {
                this.$modal.show(this.name);
            },
            closeCalendarModal() {
                this.$modal.hide(this.name);
            },
            handleClose(event) {
                if (this.error) {
                    event.cancel();
                }
            }
        },
        filters: {
            daterangeText,
            dateText
        }
    }
</script>

<style lang="scss">
    .daterange-picker {
        position: relative;
    }
    .daterange-picker__dates {
        display: none;
    }
    .daterange-picker__info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: stretch;
    }
    .daterange-picker__title {
        &_data {
            margin-right: 5px;
        }

        &_modal {
            text-align: center;
            margin-bottom: 5px;
        }
    }
    .daterange-picker__error {
        color: $red-primary;
        font-size: 18px;
        text-align: center;
    }
    .daterange-picker__value {
        color: $red-primary;
        border-bottom: 1px dashed;
    }
    .daterange-picker__modal-content {
        padding: 5px;

        & .vc-container {
            border: none;
        }
    }
    .daterange-picker__close {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 16px;

        position: absolute;
        top: 0;
        right: 0;
    }
    .daterange-picker__icon {
        width: 16px;
        height: 16px;
        fill: $red-primary;
    }
</style>
