<template>
    <div class="tone-detail__box box">
        <table class="tone-detail__table tone-detail__table_striped">
            <caption class="tone-detail__table-caption">Всего</caption>
            <colgroup>
                <col class="tone-detail__table-column tone-detail__table-column_item-title">
                <col class="tone-detail__table-column tone-detail__table-column_item-positive">
                <col class="tone-detail__table-column tone-detail__table-column_item-negative">
                <col class="tone-detail__table-column tone-detail__table-column_item-total">
                <col class="tone-detail__table-column tone-detail__table-column_item-failed">
                <col class="tone-detail__table-column tone-detail__table-column_item-tone">
            </colgroup>
            <thead>
                <tr class="tone-detail__table-row tone-detail__table-row_head">
                    <th class="tone-detail__table-cell tone-detail__table-cell_head" scope="col">
                        <span class="tone-detail__table-value">Источник</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Положительный</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Отрицательный</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Сумма рекламаций</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Необработаны</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Тональность</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{reportDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneTotal.positive | intFormat}}</span>
                            <difference
                                v-if="compareToneTotal.positive"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneTotal.positive"
                                :compareValue="compareToneTotal.positive"
                                mode="percentage"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneTotal.negative | intFormat}}</span>
                            <difference
                                v-if="compareToneTotal.negative"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneTotal.negative"
                                :compareValue="compareToneTotal.negative"
                                mode="percentage"
                                good="negative"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneTotal.total | intFormat}}</span>
                            <difference
                                v-if="compareToneTotal.total"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneTotal.total"
                                :compareValue="compareToneTotal.total"
                                mode="percentage"
                                good="none"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneTotal.failed | intFormat}}</span>
                            <difference
                                v-if="compareToneTotal.failed"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneTotal.failed"
                                :compareValue="compareToneTotal.failed"
                                mode="percentage"
                                good="negative"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <span class="tone-detail__table-value"
                            :class="`tone-detail__table-value_${reportToneTotal.tone > 0 ? 'positive' : 'negative'}`"
                        >
                            {{reportToneTotal.tone | percentFloatFormat}}
                        </span>
                </td>
                </tr>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{compareDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        {{compareToneTotal.positive | intFormat}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        {{compareToneTotal.negative | intFormat}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        {{compareToneTotal.total | intFormat}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        {{compareToneTotal.failed | intFormat}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <span class="tone-detail__table-value"
                            :class="`tone-detail__table-value_${compareToneTotal.tone > 0 ? 'positive' : 'negative'}`"
                        >
                            {{compareToneTotal.tone | percentFloatFormat}}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareItemsTotalBox",
        components: {
            Difference,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                type: Object,
                required: true
            },
            reportToneTotal: {
                type: Object,
                required: true
            },
            compareToneTotal: {
                type: Object,
                required: true
            },
        },
        filters: {
            daterangeText
        },
    }
</script>
