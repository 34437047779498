<template>
    <div class="tone-detail">
        <router-link class="tone-detail__link tone-detail__link_back link link_red"
            ref="backLink"
            v-if="reportId !== 'total' && !isUserWithOneRestaurant"
            :to="{ name: 'ToneDetail' }"
        >
            Вернуться к общему отчету по тональности
        </router-link>
        <section class="tone-detail__settings">
            <h2 class="sr-only">Настройки отчета "Тональность"</h2>
            <form class="tone-detail__controls" @submit.prevent>
                <div class="tone-detail__dateranges">
                    <daterange-picker class="tone-detail__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="tone-detail__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="tone-detail__toggles">
                    <toggle-button class="tone-detail__toggle-input"
                        :color="toggleColors"
                        v-model="modeModel"
                        :width="130"
                        :labels="modeToggleLabels"
                    />
                    <toggle-button class="tone-detail__toggle-input"
                        :color="toggleColors"
                        v-model="typeModel"
                        :width="110"
                        :labels="typeToggleLabels"
                    />
                    <toggle-button class="tone-detail__toggle-input"
                        :color="toggleColors"
                        v-model="viewModel"
                        :width="80"
                        :labels="viewToggleLabels"
                    />
                </div>
                <div class="tone-detail__selects">
                    <select class="tone-detail__select"
                        v-if="typeId === 'distribution'"
                        v-model="originModel"
                    >
                        <option class="tone-detail__option"
                            v-for="origin in reportOrigins"
                            :key="origin.id"
                            :value="origin.id"
                        >
                            {{origin.title}}
                        </option>
                    </select>
                </div>
            </form>
        </section>
        <spinner class="tone-detail__spinner" v-if="status === 'loading'" />
        <error-message class="tone-detail__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Тональность".
        </error-message>
        <section class="tone-detail__data" v-else-if="status === 'success'">
            <div class="tone-detail__base"
                v-if="modeId === 'base'"
            >
                <template v-if="typeId === 'distribution'">
                    <div class="tone-detail__box box">
                        <table v-if="viewId === 'table'"
                            class="tone-detail__table tone-detail__table_striped"
                        >
                            <colgroup>
                                <col class="tone-detail__table-column tone-detail__table-column_item-title">
                                <col class="tone-detail__table-column tone-detail__table-column_item-positive">
                                <col class="tone-detail__table-column tone-detail__table-column_item-negative">
                                <col class="tone-detail__table-column tone-detail__table-column_item-total">
                                <col class="tone-detail__table-column tone-detail__table-column_item-failed">
                                <col class="tone-detail__table-column tone-detail__table-column_item-tone">
                            </colgroup>
                            <thead>
                                <tr class="tone-detail__table-row tone-detail__table-row_head">
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'title')"
                                    >
                                        <span class="tone-detail__table-value">Название</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'title' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'title' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'positive')"
                                    >
                                        <span class="tone-detail__table-value">Положительный</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'positive' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'positive' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'negative')"
                                    >
                                        <span class="tone-detail__table-value">Отрицательный</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'negative' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'negative' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'total')"
                                    >
                                        <span class="tone-detail__table-value">Сумма рекламаций</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'total' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'total' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'failed')"
                                    >
                                        <span class="tone-detail__table-value">Необработаны</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'failed' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'failed' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                        scope="col"
                                        @click="setSorting('items', 'tone')"
                                    >
                                        <span class="tone-detail__table-value">Тональность</span>
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'tone' && sortings.items.direction === 'ASC'"
                                            icon="chevron-up"
                                        />
                                        <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                            v-if="sortings.items.field === 'tone' && sortings.items.direction === 'DESC'"
                                            icon="chevron-down"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="tone-detail__table-row tone-detail__table-row_body tone-detail__table-row_pinned"
                                    v-if="reportToneItems.length > 1"
                                >
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                                        <template v-if="originId === 'restaurants'">Все рестораны</template>
                                        <template v-else-if="originId === 'source'">Все источники</template>
                                        <template v-else-if="originId === 'group'">Все направления</template>
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneTotal.positive | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneTotal.negative | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneTotal.total | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneTotal.failed | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        <span class="tone-detail__table-value"
                                            :class="`tone-detail__table-value_${reportToneTotal.tone > 0 ? 'positive' : 'negative'}`"
                                        >
                                            {{reportToneTotal.tone | percentFloatFormat}}
                                        </span>
                                    </td>
                                </tr>
                                <tr class="tone-detail__table-row tone-detail__table-row_body"
                                    v-for="reportToneItem in reportToneItems" :key="reportToneItem.title"
                                >
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body  tone-detail__table-cell_title">
                                        <router-link class="link link_red"
                                            v-if="originId === 'restaurants'"
                                            :to="{ name: 'ToneDetail', params: { id: reportToneItem.id } }"
                                            :title="`Смотреть тональность по ресторану ${reportToneItem.title}`">
                                            {{reportToneItem.title}}
                                        </router-link>
                                        <template v-else>{{reportToneItem.title}}</template>
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneItem.positive | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneItem.negative | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneItem.total | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        {{reportToneItem.failed | intFormat}}
                                    </td>
                                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                        <span class="tone-detail__table-value"
                                            :class="`tone-detail__table-value_${reportToneItem.tone > 0 ? 'positive' : 'negative'}`"
                                        >
                                            {{reportToneItem.tone | percentFloatFormat}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <highcharts v-else-if="viewId === 'chart'"
                            class="tone-detail__chart"
                            :options="itemsChartOptions"
                            :key="'baseChartItems'"
                        />
                    </div>
                </template>
                <template v-else-if="typeId === 'reason'">
                    <template v-if="viewId === 'table'">
                        <div class="tone-detail__box tone-detail__box_reason-table box">
                            <table class="tone-detail__table tone-detail__table_reasons tone-detail__table_striped">
                                <colgroup>
                                    <col class="tone-detail__table-column tone-detail__table-column_reasons-title">
                                    <col class="tone-detail__table-column tone-detail__table-column_reasons-total">
                                </colgroup>
                                <thead>
                                    <tr class="tone-detail__table-row tone-detail__table-row_head">
                                        <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('reasons', 'title')"
                                        >
                                            <span class="tone-detail__table-value">Категория</span>
                                            <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                                v-if="sortings.reasons.field === 'title' && sortings.reasons.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                                v-if="sortings.reasons.field === 'title' && sortings.reasons.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </th>
                                        <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar tone-detail__table-cell_sortable"
                                            scope="col"
                                            @click="setSorting('reasons', 'total')"
                                        >
                                            <span class="tone-detail__table-value">Количество</span>
                                            <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                                v-if="sortings.reasons.field === 'total' && sortings.reasons.direction === 'ASC'"
                                                icon="chevron-up"
                                            />
                                            <svg-icon class="tone-detail__table-value tone-detail__table-value_icon"
                                                v-if="sortings.reasons.field === 'total' && sortings.reasons.direction === 'DESC'"
                                                icon="chevron-down"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tone-detail__table-row tone-detail__table-row_body"
                                        v-for="reportToneReason in reportToneReasons" :key="reportToneReason.title"
                                    >
                                        <td class="tone-detail__table-cell tone-detail__table-cell_body">
                                            {{reportToneReason.title}}
                                        </td>
                                        <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                                            {{reportToneReason.total | intFormat}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    <template  v-else-if="viewId === 'chart'">
                        <div class="tone-detail__box box">
                            <highcharts class="tone-detail__chart" :options="reasonsChartOptions" :key="'baseChartReasons'" />
                        </div>
                    </template>
                </template>
            </div>
            <div class="tone-detail__compare"
                :class="`tone-detail__compare_${typeId}-${viewId}`"
                v-else-if="modeId === 'compareOtherPeriod'"
            >
                <p class="tone-detail__error" v-if="!compareTone">Выберите период сравнения.</p>
                <template v-else>
                    <template v-if="typeId === 'distribution'">
                        <template v-if="viewId === 'table'">
                            <compare-items-total-box
                                :reportDaterange="reportDaterange"
                                :compareDaterange="compareDaterange"
                                :reportToneTotal="reportToneTotal"
                                :compareToneTotal="compareToneTotal"
                            />
                            <compare-items-item-box
                                v-for="reportToneItem in reportToneItems"
                                :key="reportToneItem.title"
                                :originId="originId"
                                :reportDaterange="reportDaterange"
                                :compareDaterange="compareDaterange"
                                :reportToneItem="reportToneItem"
                                :compareToneItems="compareToneItems"
                            />
                        </template>
                        <div  v-else-if="viewId === 'chart'"
                            class="tone-detail__box box"
                        >
                            <highcharts class="tone-detail__chart" :options="itemsChartOptions" :key="'compareChartItems'" />
                        </div>
                    </template>
                    <template v-else-if="typeId === 'reason'">
                        <template v-if="viewId === 'table'">
                            <compare-reasons-item-box
                                v-for="reportToneReason in reportToneReasons"
                                :key="reportToneReason.title"
                                :reportDaterange="reportDaterange"
                                :compareDaterange="compareDaterange"
                                :reportToneReason="reportToneReason"
                                :compareToneReasons="compareToneReasons"
                            />
                        </template>
                        <div  v-else-if="viewId === 'chart'"
                            class="tone-detail__box box"
                        >
                            <highcharts class="tone-detail__chart" :options="reasonsChartOptions" :key="'compareChartReasons'" />
                        </div>
                    </template>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import DaterangePicker from "@/components/DaterangePicker";
    import CompareItemsTotalBox from "./tone-detail/CompareItemsTotalBox";
    import CompareItemsItemBox from "./tone-detail/CompareItemsItemBox";
    import CompareReasonsItemBox from "./tone-detail/CompareReasonsItemBox";

    export default {
        name: "ToneDetail",
        props: ["id"],
        components: {
            DaterangePicker,
            CompareItemsTotalBox,
            CompareItemsItemBox,
            CompareReasonsItemBox,
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.tone.compareDaterange,
                reportId: state => state.tone.reportId,
                modeId: state => state.tone.modeId,
                typeId: state => state.tone.typeId,
                viewId: state => state.tone.viewId,
                originId: state => state.tone.originId,
                origins: state => state.tone.origins,
                sortings: state => state.tone.sortings,
                data: state => state.tone.data,
            }),
            ...mapGetters([
                "userRestaurants",
            ]),
            isUserWithOneRestaurant() {
                return this.userRestaurants?.ids?.length === 1;
            },
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(value) {
                    this.setReportDaterange(value);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setToneParameter({ parameter: "compareDaterange", value });
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setToneParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            typeModel: {
                get() {
                    return this.typeId === "reason";
                },
                set(isReasons) {
                    this.setToneParameter({
                        parameter: "typeId",
                        value: isReasons ? "reason" : "distribution"
                    });
                }
            },
            typeToggleLabels() {
                return {
                    unchecked: "Распределение",
                    checked: "Классификация"
                };
            },
            viewModel: {
                get() {
                    return this.viewId === "chart";
                },
                set(isChart) {
                    this.setToneParameter({
                        parameter: "viewId",
                        value: isChart ? "chart" : "table"
                    });
                }
            },
            viewToggleLabels() {
                return {
                    unchecked: "Таблица",
                    checked: "График"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            reportOrigins() {
                return this.origins?.filter(origin => {
                    if (this.reportId === "total") {
                        return true;
                    }

                    return origin.id !== "restaurants";
                });
            },
            originModel: {
                get() {
                    return this.originId;
                },
                set(value) {
                    this.setToneParameter({ parameter: "originId", value });
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredTones() {
                return this.requiredDateranges.map(daterange => this.data[daterangeKey(daterange)]);
            },
            reportTone() {
                if (this.reportId === "total") {
                    return this.data?.[daterangeKey(this.reportDaterange)];
                } else if (this.reportId === "restaurant") {
                    return this.data?.[daterangeKey(this.reportDaterange)]?.restaurants?.find(({ id }) => id === this.id);
                }
            },
            reportToneItems() {
                return this.reportTone?.[this.originId]?.sort((toneItem1, toneItem2) => {
                    let compare = this.sortings.items.field === "title" ? compareStrings : compareNumbers;

                    return compare(
                        toneItem1[this.sortings.items.field],
                        toneItem2[this.sortings.items.field],
                        this.sortings.items.direction
                    );
                });
            },
            reportToneReasons() {
                return this.reportTone?.reason?.sort((toneReason1, toneReason2) => {
                    let compare = this.sortings.reasons.field === "title" ? compareStrings : compareNumbers;

                    return compare(
                        toneReason1[this.sortings.reasons.field],
                        toneReason2[this.sortings.reasons.field],
                        this.sortings.reasons.direction
                    );
                });
            },
            reportToneTotal() {
                if (this.reportId === "total") {
                    return this.reportTone?.total;
                } else if (this.reportId === "restaurant") {
                    return {
                        positive: this.reportTone?.positive,
                        negative: this.reportTone?.negative,
                        total: this.reportTone?.total,
                        failed: this.reportTone?.failed,
                        tone: this.reportTone?.tone,
                    }
                }
            },
            compareTone() {
                if (isDaterangeSet(this.compareDaterange)) {
                    if (this.reportId === "total") {
                        return this.data?.[daterangeKey(this.compareDaterange)];
                    } else if (this.reportId === "restaurant") {
                        return this.data?.[daterangeKey(this.compareDaterange)]?.restaurants?.find(({ id }) => id === this.id);
                    }
                }
            },
            compareToneItems() {
                return this.compareTone?.[this.originId];
            },
            compareToneReasons() {
                return this.compareTone?.reason;
            },
            compareToneTotal() {
                if (this.reportId === "total") {
                    return this.compareTone?.total;
                } else if (this.reportId === "restaurant") {
                    return {
                        positive: this.compareTone?.positive,
                        negative: this.compareTone?.negative,
                        total: this.compareTone?.total,
                        failed: this.compareTone?.failed,
                        tone: this.compareTone?.tone,
                    }
                }
            },
            itemsChartOptions() {
                let options = {
                    chart: {
                        type: "column"
                    },
                    colors: ["#e0121a", "#ff5261"],
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: []
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Рекламации"
                        },
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: "bold",
                                color: "gray"
                            }
                        }
                    },
                    legend: {
                        align: "center",
                        verticalAlign: "bottom",
                    },
                    tooltip: {
                        headerFormat: "<b>{point.x}</b><br/>",
                        pointFormat: "{series.name}: {point.y}<br/>Всего: {point.stackTotal}"
                    },
                    plotOptions: {
                        column: {
                            stacking: "normal",
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    series: []
                };

                if (this.modeId === "base") {
                    options.series.push(...[{
                        name: "Отрицательный",
                        stack: "report",
                        data: []
                    }, {
                        name: "Положительный",
                        stack: "report",
                        data: []
                    }]);
                } else if (this.modeId === "compareOtherPeriod" && this.compareToneItems) {
                    let series = [{
                        name: `${daterangeText(this.reportDaterange)} (отрицательный)`,
                        stack: "report",
                        data: []
                    }, {
                        name: `${daterangeText(this.reportDaterange)} (положительный)`,
                        stack: "report",
                        data: []
                    }, {
                        name: `${daterangeText(this.compareDaterange)} (отрицательный)`,
                        stack: "compare",
                        data: []
                    }, {
                        name: `${daterangeText(this.compareDaterange)} (положительный)`,
                        stack: "compare",
                        data: []
                    }];

                    options.series.push(...series);
                }

                this.reportToneItems?.forEach(reportToneItem => {
                    options.xAxis.categories.push(reportToneItem.title);

                    options.series[0].data.push(reportToneItem.negative);
                    options.series[1].data.push(reportToneItem.positive);

                    if (this.modeId === "compareOtherPeriod" && this.compareToneItems) {
                        let compareToneItem = this.compareToneItems?.find(({ title }) => title === reportToneItem.title);

                        options.series[2].data.push(compareToneItem?.negative || null);
                        options.series[3].data.push(compareToneItem?.positive || null);
                    }
                });

                return options;
            },
            reasonsChartOptions() {
                if (this.modeId === "base") {
                    return {
                        chart: {
                            plotBackgroundColor: null,
                            plotBorderWidth: null,
                            plotShadow: false,
                            type: "pie"
                        },
                        title: {
                            text: null
                        },
                        tooltip: {
                            pointFormat: '{point.percentage:.1f}% ({point.y} из {point.total})'
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '{point.name}: {point.percentage:.1f} %'
                                }
                            }
                        },
                        series: [{
                            data: [...this.reportToneReasons?.map(({ title: name, total: y })  => ({ name, y}))]
                        }]
                    };
                } else if (this.modeId === "compareOtherPeriod") {
                    let options = {
                        chart: {
                            type: "column"
                        },
                        colors: ["#e0121a", "#ff5261"],
                        title: {
                            text: null
                        },
                        xAxis: {
                            categories: []
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: "Рекламации"
                            },
                            stackLabels: {
                                enabled: true,
                                style: {
                                    fontWeight: "bold",
                                    color: "gray"
                                }
                            }
                        },
                        legend: {
                            align: "center",
                            verticalAlign: "bottom",
                        },
                        tooltip: {
                            headerFormat: "<b>{point.x}</b><br/>",
                            pointFormat: "{series.name}: {point.y}"
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        },
                        series: [{
                            name: daterangeText(this.reportDaterange),
                            data: []
                        }, {
                            name: daterangeText(this.compareDaterange),
                            data: []
                        }]
                    };

                    this.reportToneReasons?.forEach(reportToneReason => {
                        options.xAxis.categories.push(reportToneReason.title);

                        options.series[0].data.push(reportToneReason.total);

                        if (this.compareToneReasons) {
                            let compareToneReason = this.compareToneReasons?.find(({ title }) => title === reportToneReason.title);
                            options.series[1].data.push(compareToneReason?.total || null);
                        }
                    });

                    return options;
                }
            }
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setReportDaterange",
                "setToneParameter",
                "setToneSorting",
            ]),
            ...mapActions([
                "requestTones"
            ]),
            showReport() {
                this.status = "success";
            },
            loadTones() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[daterangeKey(daterange)])
                );

                this.requestTones(daterangesWithoutData).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            setSorting(table, field) {
                this.setToneSorting({ table, field });
            },
            initToneDetail() {
                if (typeof this.id !== "undefined" && !this.userRestaurants?.ids?.includes(this.id)) {
                    this.$router.push({ name: "Error401" });
                } else {
                    if (typeof this.id === "undefined") {
                        if (this.isUserWithOneRestaurant) {
                            this.$router.push({
                                name: "ToneDetail",
                                params:{
                                    id: this.userRestaurants?.ids[0]
                                }
                            });
                        } else {
                            this.setToneParameter({ parameter: "reportId", value: "total" });
                            this.setToneParameter({ parameter: "originId", value: "restaurants" });
                        }
                    } else {
                        this.setToneParameter({ parameter: "reportId", value: "restaurant" });

                        if (this.originId === "restaurants") {
                            this.setToneParameter({ parameter: "originId", value: "source" });
                        }

                        let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                        if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                            this.setTitle(`${this.title} "${restaurantTitle}"`);
                        }
                    }

                    if (this.requiredTones.every(Boolean)) {
                        this.showReport();
                    } else {
                        this.loadTones();
                    }
                }
            }
        },
        created() {
            this.initToneDetail();
        },
        watch: {
            id() {
                this.initToneDetail();
            },
            requiredTones(requiredTones) {
                if (!requiredTones.every(Boolean)) {
                    this.loadTones();
                }
            }
        }
    }
</script>

<style lang="scss">
    .tone-detail__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .tone-detail__settings {
        margin-bottom: 15px;
    }
    .tone-detail__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .tone-detail__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 20px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: center;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .tone-detail__selects {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 20px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: center;

        margin-top: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .tone-detail__select {
        padding: 4px 8px;
        border-color: $gray-line;
        border-radius: 4px;
    }
    .tone-detail__box {
        padding: 16px;
        overflow-x: auto;

        &_reason-table {
            @include desktop {
                display: inline-block;
            }
        }
    }
    .tone-detail__compare {
        display: grid;
        grid-gap: 15px;

        &_reason-table {
            place-items: start;
        }
    }
    .tone-detail__chart {
        &_reasons {
            @include desktop {
                grid-column: 2 / 4;
            }
        }
    }
    .tone-detail__table {
        width: 100%;

        &_reasons {
            @include desktop {
                width: auto;
            }
        }
    }
    .tone-detail__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .tone-detail__table-column {
        &_total {
            width: 20%;
        }

        &_item-title {
            width: 25%;
        }

        &_item-positive {
            width: 14%;
        }

        &_item-negative {
            width: 14%;
        }

        &_item-total {
            width: 17%;
        }

        &_item-failed {
            width: 14%;
        }

        &_item-tone {
            width: 16%;
        }

        &_reasons-title {
            width: 400px;
        }
    }
    .tone-detail__table-row {
        &_body {
            .tone-detail__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .tone-detail__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .tone-detail__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
        }

        &_tar {
            text-align: right;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .tone-detail__table-cell-content {
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .tone-detail__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
    .tone-detail__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .tone-detail__table-text {
        flex-grow: 1;
        vertical-align: middle;
    }
</style>
