<template>
    <span :class="diffClass" v-if="Math.abs(diff) > 0.0001">{{format(diff, filter)}}</span>
</template>

<script>
    import * as filters from "@/helpers/filters";

    export default {
        name: "Difference",
        props: {
            primaryValue: {
                type: Number,
                required: true
            },
            compareValue: {
                type: Number,
                required: true
            },
            mode: {
                type: String,
                required: true
            },
            filter: {
                type: String,
                required: true
            },
            good: {
                type: String,
                default: "positive"
            }
        },
        computed: {
            diff() {
                switch (this.mode) {
                    case "percentage":
                        return (this.primaryValue / this.compareValue) - 1;
                    case "subtraction":
                        return this.primaryValue - this.compareValue;
                }
            },
            diffClass() {
                let classNames = {
                    "difference": true,
                    "difference_positive":  this.diff > 0,
                    "difference_negative":  this.diff < 0,
                };

                if (this.good !== "none") {
                    classNames.difference_good = this.good === "positive" ? this.diff > 0 : this.diff < 0;
                    classNames.difference_bad = this.good === "negative" ? this.diff > 0 : this.diff < 0;
                }

                return classNames;
            }
        },
        methods: {
            ...filters,
            format(value, filterName) {
                return this[filterName](value);
            }
        }
    }
</script>

<style lang="scss">
    .difference {
        font-size: 0.75em;

        &_good {
            color: green;
        }

        &_bad {
            color: red;
        }

        &_positive {
            &::before {
                content: "+";
            }
        }
    }
</style>
