import compareDatesDesc from "date-fns/compareDesc";
import compareDatesAsc from "date-fns/compareAsc";

export function compareStrings(a, b, direction) {
    a = a ?? "";
    b = b ?? "";

    let comparisson = a.localeCompare(b, "ru");

    if (comparisson > 0) {
        return direction === "DESC" ? -1 : 1;
    } else if (comparisson < 0) {
        return direction === "DESC" ? 1 : -1;
    }

    return 0;
}

export function compareNumbers(a, b, direction) {
    a = a ?? Number.MIN_VALUE;
    b = b ?? Number.MIN_VALUE;

    return direction === "DESC" ? b - a :  a - b;
}

export function compareBooleans(a, b, direction) {
    a = a ?? false;
    b = b ?? false;

    if (a && !b) {
        return direction === "DESC" ? -1 : 1;
    } else if (!a && b) {
        return direction === "DESC" ? 1 : -1;
    }

    return 0;
}

export function compareDates(a, b, direction) {
    a = a ?? new Date(-8640000000000000);
    b = b ?? new Date(-8640000000000000);

    return direction === "DESC" ? compareDatesDesc(a, b) :  compareDatesAsc(a, b);
}
