<template>
    <div class="tone-detail__box box">
        <table class="tone-detail__table tone-detail__table_striped">
            <caption class="tone-detail__table-caption">
                <router-link class="link link_red"
                    v-if="originId === 'restaurants'"
                    :to="{ name: 'ToneDetail', params: { id: reportToneItem.id } }"
                    :title="`Смотреть тональность по ресторану ${reportToneItem.title}`">
                    {{reportToneItem.title}}
                </router-link>
                <template v-else>{{reportToneItem.title}}</template>
            </caption>
            <colgroup>
                <col class="tone-detail__table-column tone-detail__table-column_item-title">
                <col class="tone-detail__table-column tone-detail__table-column_item-positive">
                <col class="tone-detail__table-column tone-detail__table-column_item-negative">
                <col class="tone-detail__table-column tone-detail__table-column_item-total">
                <col class="tone-detail__table-column tone-detail__table-column_item-failed">
                <col class="tone-detail__table-column tone-detail__table-column_item-tone">
            </colgroup>
            <thead>
                <tr class="tone-detail__table-row tone-detail__table-row_head">
                    <th class="tone-detail__table-cell tone-detail__table-cell_head" scope="col">
                        <span class="tone-detail__table-value">Источник</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Положительный</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Отрицательный</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Сумма рекламаций</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Необработаны</span>
                    </th>
                    <th class="tone-detail__table-cell tone-detail__table-cell_head tone-detail__table-cell_tar" scope="col">
                        <span class="tone-detail__table-value">Тональность</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{reportDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneItem.positive | intFormat}}</span>
                            <difference
                                v-if="compareToneItem && compareToneItem.positive"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneItem.positive"
                                :compareValue="compareToneItem.positive"
                                mode="percentage"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneItem.negative | intFormat}}</span>
                            <difference
                                v-if="compareToneItem && compareToneItem.negative"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneItem.negative"
                                :compareValue="compareToneItem.negative"
                                mode="percentage"
                                good="negative"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneItem.total | intFormat}}</span>
                            <difference
                                v-if="compareToneItem && compareToneItem.total"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneItem.total"
                                :compareValue="compareToneItem.total"
                                mode="percentage"
                                good="none"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <div class="tone-detail__table-cell-content">
                            <span class="tone-detail__table-value">{{reportToneItem.failed | intFormat}}</span>
                            <difference
                                v-if="compareToneItem && compareToneItem.failed"
                                class="tone-detail__table-value"
                                :primaryValue="reportToneItem.failed"
                                :compareValue="compareToneItem.failed"
                                mode="percentage"
                                good="negative"
                                filter="percentIntFormat"
                            />
                        </div>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <span class="tone-detail__table-value"
                            :class="`tone-detail__table-value_${reportToneItem.tone > 0 ? 'positive' : 'negative'}`"
                        >
                            {{reportToneItem.tone | percentFloatFormat}}
                        </span>
                </td>
                </tr>
                <tr class="tone-detail__table-row tone-detail__table-row_body">
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_title">
                        {{compareDaterange | daterangeText}}
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneItem">
                            {{compareToneItem.positive | intFormat}}
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneItem">
                            {{compareToneItem.negative | intFormat}}
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneItem">
                            {{compareToneItem.total | intFormat}}
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneItem">
                            {{compareToneItem.failed | intFormat}}
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                    <td class="tone-detail__table-cell tone-detail__table-cell_body tone-detail__table-cell_tar">
                        <template v-if="compareToneItem">
                            <span class="tone-detail__table-value"
                                :class="`tone-detail__table-value_${compareToneItem.tone > 0 ? 'positive' : 'negative'}`"
                            >
                                {{compareToneItem.tone | percentFloatFormat}}
                            </span>
                        </template>
                        <template v-else>нет данных</template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { daterangeText } from "@/helpers/daterange";
    import Difference from "@/components/Difference";

    export default {
        name: "CompareItemsItemBox",
        components: {
            Difference,
        },
        props: {
            originId: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            compareDaterange: {
                type: Object,
                required: true
            },
            reportToneItem: {
                type: Object,
                required: true
            },
            compareToneItems: {
                type: Array,
                required: true
            },
        },
        computed: {
            compareToneItem() {
                return this.compareToneItems?.find(({ title }) => title === this.reportToneItem.title);
            }
        },
        filters: {
            daterangeText
        },
    }
</script>
